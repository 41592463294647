html {
  scroll-behavior: smooth !important;
}
.container {
  margin: 0 auto;
  padding: 0 1.5rem;
  width: 100%;
  position: relative;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media (min-width: 1400px) {
    max-width: 1260px;
  }
  @media (min-width: 1600px) {
    max-width: 1260px;
  }
}
main {
  overflow-x: hidden;
}

.body__home {
  @import "./components/secao_servicos";
}

.body__sobre {
  @import "./components/navtab_sobre";
}

.body__servicos {
  @import "./components/page_servicos";
}

.body__contato {
  @import "./components/page_contato";
}
